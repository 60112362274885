import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError, map, of } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.getAdmin().pipe(
    map(admin => {
      if (admin) {
        return true;
      } else {
        router.navigate(['login']);
        return false;
      }
    }),
    catchError(() => {
      router.navigate(['login']);
      return of(false);
    })
  );
};
