<div class="h-2/5 bg-gradient-to-r from-orange-500 to-pink-600 flex flex-col relative">
  <div class="min-h-screen flex flex-col flex-nowrap items-center justify-center bg-white-300">
    <div class="flex flex-col shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md lg:w-2/5 sm:w-9/12 sm:max-w-md"  style="background: white;">
      <div class="flex justify-center mb-5">
        <img src="/assets/images/logo.png" width="150px">
    </div>
      <div class="flex justify-center">
          <p class="sm:text-2xl">Reset Password</p>
      </div>
      <div class="mt-2">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="flex flex-col mb-6">
            <label for="email" class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Email Address</label>
            <div class="relative">
              <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                <svg class="h-6 w-6 stroke-pink-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="pink">
                  <path d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" />
                </svg>
              </div>
              <input id="email" type="email" formControlName="email" class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" stroke="pink" placeholder="Email Address" />
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <label for="password" class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">New Password</label>
            <div class="relative">
              <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                <span>
                  <svg class="h-6 w-6 stroke-pink-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="pink">
                    <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                </span>
              </div>
              <input id="password" type="password" formControlName="password" class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Password" />
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <label for="password" class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Password Confirmation</label>
            <div class="relative">
              <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                <span>
                  <svg class="h-6 w-6 stroke-pink-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="pink">
                    <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                </span>
              </div>
              <input id="password_confirmation" type="password" formControlName="password_confirmation" class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" placeholder="Password Confirmation" />
            </div>
          </div>

          <div class="flex w-full gap-1">
            <button routerLink="/login" type="button" class="flex items-center justify-center focus:outline-none w-full rounded text-pink-500 ring-1 ring-pink-500 text-sm sm:text-base bg-white hover:bg-gray-300  py-2 transition duration-150 ease-in">
              <span class="mr-2">Cancel</span>
            </button>

            <button [disabled]="loading" type="submit" class="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-pink-600 hover:bg-pink-700 rounded py-2 w-full transition duration-150 ease-in disabled:bg-pink-700 disabled:cursor-not-allowed">
              <div class="flex justify-center items-center mr-2" *ngIf="loading">
                <span class="animate-spin">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white"><path d="M480-96q-78.72 0-148.8-30.24-70.08-30.24-122.4-82.56-52.32-52.32-82.56-122.4Q96-401.28 96-480q0-79.68 30.22-149.28 30.21-69.6 82.49-121.92 52.28-52.32 122.3-82.56Q401.04-864 479.69-864 496-864 506-854t10 25.51q0 15.51-10 26T480-792q-129.67 0-220.84 90.5Q168-611 168-480.5T259.16-259q91.17 91 220.84 91 131 0 221.5-91.16Q792-350.33 792-480q0-16 10.49-26t26-10Q844-516 854-506q10 10 10 26.31 0 78.65-30.24 148.68-30.24 70.02-82.56 122.3-52.32 52.28-121.92 82.49Q559.68-96 480-96Z"/></svg>
                </span>
              </div>
              <span class="mr-2">Submit</span>
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

