import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Admin } from '../interfaces/admin';
import { Response } from '../interfaces/response';
import { Publisher } from '../interfaces/publisher';
import { RolesForm } from '../interfaces/roles-form';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private apiBase = environment.apiUrl;
  admin = new BehaviorSubject<Admin|null>(null);

  constructor(
    private http: HttpClient,
  ) {}

  approve(data:any): Observable<any> {
    return this.http.post<any>(this.apiBase + 'api/creator/approve-creator', data);
  }

  getCreator(id: any){
    return this.http.get<any>(this.apiBase + 'api/creator/get-creator/'+ id );
  }

  updateCreator(data:any, id:any): Observable<any> {
    return this.http.post<any>(this.apiBase + 'api/creator/update-creator/'+ id, data);
  }

  publisher(){
    return this.http.get<Response<Publisher[]>>(this.apiBase + 'api/publishers');
  }

  inviteMember(data:any): Observable<any> {
    return this.http.post<any>(this.apiBase + 'api/publisher/invite-member', data);
  }

  getAdmins(filter  : { search?: string, role?: string, per_page? : number, current_page? : number }) : Observable<Response<Admin[]>> {
    return this.http.get<Response<Admin[]>>(`${this.apiBase}api/publisher/admin`, { params : filter });
  }

  getCurrentPublisherAdmins(key : string, filter  : { search?: string, role?: string, per_page? : number, current_page? : number }) : Observable<Response<Admin[]>> {
    return this.http.get<Response<Admin[]>>(`${this.apiBase}api/publisher/${key}/admin`, { params : filter });
  }

  getAdmin(id : number) : Observable<Response<Admin>> {
    return this.http.get<Response<Admin>>(`${this.apiBase}api/publisher/admin/${id}`);
  }

  updateAdmin(key : number, body : { name: string, email : string, roles : RolesForm[] }) : Observable<Response<void>> {
    return this.http.post<Response<void>>(`${this.apiBase}api/publisher/admin/${key}`, body, { params : { '_method' : 'PATCH' }});
  }

  deleteCurrentPublisherAdmin(adminId: number, publisherkey : string) : Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${this.apiBase}api/publisher/${publisherkey}/admin/${adminId}`);
  }

}
