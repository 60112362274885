import { Component } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InviteService } from '../services/invite.service';
import { SnackbarService } from '../services/snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invite-accept',
  templateUrl: './invite-accept.component.html',
  styleUrls: ['./invite-accept.component.scss']
})
export class InviteAcceptComponent {
  isUserExist = false;
  publisher = '';
  form = new UntypedFormGroup({
    email : new FormControl<string>('', [Validators.required, Validators.email]),
    token : new FormControl<string>('', Validators.required),
  });
  loading : boolean = false;
  recaptchaV3ServiceSubscription : Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inviteService: InviteService,
    private snackbarService : SnackbarService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.publisher = params['publisher'];
      this.isUserExist = Boolean(Number(params['exist']));

      if (!this.isUserExist) {
        this.form.addControl('name', new FormControl<string>('', Validators.required));
        this.form.addControl('password', new FormControl<string>('', Validators.required));
        this.form.addControl('password_confirmation', new FormControl<string>('', Validators.required));
      }

      this.form.patchValue({
        email : params['email'],
        token : params['token'],
      });
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.snackbarService.openSnackbar('Please fill the form.');
    } else {
      this.loading = true;


      this.recaptchaV3ServiceSubscription = this.recaptchaV3Service.execute('publisher_sign_up')
      .subscribe((recaptcha) => {
          let formValue : {
            email : string, token : string, recaptcha : string, name? : string, password? : string, password_confirmation? : string,
          } = {
            email : this.form.get('email')?.value ?? '',
            token : this.form.get('token')?.value ?? '',
            recaptcha : recaptcha,
          }

          if (!this.isUserExist) {
            formValue = {
              email : this.form.get('email')?.value ?? '',
              name : this.form.get('name')?.value ?? '',
              token : this.form.get('token')?.value ?? '',
              password : this.form.get('password')?.value ?? '',
              password_confirmation : this.form.get('password_confirmation')?.value ?? '',
              recaptcha : recaptcha,
            }
          }

          this.inviteService.acceptInvite(formValue).subscribe({
            next: (response) => {
              this.snackbarService.openSnackbar(response.message);
              this.router.navigate(['/login']);
            },
            error: (errors) => {
              this.snackbarService.openSnackbar(errors.error.message );
              this.loading = false;
            }
          });
        });
    }
  }
}
