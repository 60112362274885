import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';

export const noAuthGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.getAdmin().pipe(
    map(admin => {
      if (admin) {
        router.navigate(['']);
        return false;
      } else {
        return true;
      }
    }),
    catchError(error => {
      return of(true);
    })
  );
};
