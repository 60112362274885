import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  loginForm = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ]),
    password: new FormControl('', [ Validators.required, Validators.minLength(8) ]),
  });
  loading : boolean = false;
  recaptchaV3ServiceSubscription : Subscription = new Subscription();

  constructor(
    private snackbarService: SnackbarService,
    private authService : AuthService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  onSubmit(): void {
    if (this.loginForm.invalid) {
      this.snackbarService.openSnackbar('Please fill the login form.');
    } else {
      this.loading = true;

      this.recaptchaV3ServiceSubscription = this.recaptchaV3Service.execute('login')
        .subscribe((recaptcha) => {
          this.authService.cookieInit().subscribe(() => {
            this.authService.login({
              email : this.loginForm.get('email')?.value ?? '',
              password : this.loginForm.get('password')?.value ?? '',
              recaptcha : recaptcha,
            }).subscribe({
                next: () => {
                  this.snackbarService.openSnackbar('You are successfully logged in');
                  this.router.navigate(['']);
                  this.loading = false;
                },
                error: (error) => {
                  if (error.error?.message) {
                    this.snackbarService.openSnackbar( error.error.message );
                  }
                  this.loading = false;
                }
            });
          });
        });
    }
  }

  ngOnDestroy() {
    if (this.recaptchaV3ServiceSubscription) {
      this.recaptchaV3ServiceSubscription.unsubscribe();
    }
  }

}
