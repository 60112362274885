import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AdminRoles } from '../interfaces/admin-roles';
import { AdminRole } from '../interfaces/admin-role';
import { AdminService } from './admin.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Response } from '../interfaces/response';
import { AdminDashboard } from '../interfaces/admin-dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  adminRoles = new BehaviorSubject<AdminRoles>({});
  activeAdminRole = new BehaviorSubject<AdminRole|null>(null);
  mode = new BehaviorSubject<'admin'|'publisher'|null>(null);
  permissions = new BehaviorSubject<string[]>([]);
  httpStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private apiBase = environment.apiUrl;

  constructor(
    private adminService : AdminService,
    private router : Router,
    private http: HttpClient,
  ) {
    this.adminService.admin.subscribe( admin => {
      if (admin) {
        this.setView(null);

        if (admin?.adminRoles){
          this.adminRoles.next(admin?.adminRoles);
        } else {
          this.adminRoles.next({});
        }

        if (admin?.permission?.length) {
          this.setView('admin');
        } else if (admin?.adminRoles){
          const key = Object.keys(admin?.adminRoles).length === 0 ? null : Object.keys(admin?.adminRoles)[0];

          if (key) {
            this.setView('publisher', key);
          }
        }
      }
    });
  }

  setView(mode : 'admin'|'publisher'|null, key? : string) {
    const oldMode = this.mode.value;

    if (mode == 'admin' && this.adminService.admin.value?.permission?.length) {
      this.permissions.next(this.adminService.admin.value.permission);
      this.activeAdminRole.next(null);
    } else if (this.adminRoles.value) {
      if (!key) {
        key =  Object.keys(this.adminRoles.value)[0];
      }

      if (this.adminRoles.value[key]) {
        this.activeAdminRole.next(this.adminRoles.value[key]);
        this.permissions.next(this.adminRoles.value[key].permission);
        mode = 'publisher';
      }
    } else {
      mode = null;
      this.activeAdminRole.next(null);
    }

    this.mode.next(mode);

    if (oldMode !== null && oldMode !== mode) {
      this.router.navigate(['']);
    } else if ( oldMode === 'publisher' &&  mode == 'publisher' ) {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }

  }

  public filterNullAndUndefined(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== '')
    );
  }

  public convertToFormData(obj: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();

    for (const property in obj) {
      if (!obj.hasOwnProperty(property) || obj[property] === undefined) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (obj[property] instanceof Date) {
        formData.append(formKey, obj[property].toISOString());
      } else if (obj[property] instanceof File) {
        formData.append(formKey, obj[property], obj[property].name);
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        this.convertToFormData(obj[property], formData, formKey);
      } else {
        formData.append(formKey, obj[property]);
      }
    }

    return formData;
  }

  getDashboard( params : { start_date: string, end_date : string }) : Observable<Response<AdminDashboard>> {
    return this.http.get<Response<AdminDashboard>>(`${this.apiBase}api/admin/data-insight`, { params });
  }
}
