import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  headerName = 'X-XSRF-TOKEN';

  constructor(
    private httpXsrfTokenExtractor: HttpXsrfTokenExtractor,
    private dashboardService: DashboardService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.dashboardService.httpStatus.next(true);
    request = request.clone( request.clone({ withCredentials: true}));

    if (!(request.method === 'GET' || request.method === 'HEAD')) {
      const token = this.httpXsrfTokenExtractor.getToken();

      if (token !== null && !request.headers.has(this.headerName)) {
        request = request.clone( request.clone({ headers: request.headers.set(this.headerName, token) }));
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event.type !== 0) {
          this.dashboardService.httpStatus.next(false);
        }
      }),
      catchError( response => {
        this.dashboardService.httpStatus.next(false);
        return throwError(() => response);
      })
    );
  }
}
