import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar,
    private route: Router) { }


  openSnackbar(message: string, redirectTo: string = '', action: string = '') {
      const snackBarRef = this.snackBar.open(message, action, {duration: 2000});
      snackBarRef.afterDismissed().subscribe(info => {
          if (redirectTo !== '') {
              this.route.navigate([redirectTo]);
          }
      });
  }
}
