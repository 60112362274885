import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private apiBase = environment.apiUrl;

  constructor(private http: HttpClient) {}

  acceptInvite(data: { email : string, token : string, recaptcha : string, name? : string, password? : string, password_confirmation? : string}): Observable<any> {
    return this.http.post(`${this.apiBase}api/publisher/invite/accept`, data);
  }
}
