import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { authGuard } from './guards//auth.guard';
import { noAuthGuard } from './guards/no-auth.guard';
import { InviteAcceptComponent } from './invite-accept/invite-accept.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login',
    component: LoginComponent,
    data: {
      title : 'Login'
    },
    canActivate: [ noAuthGuard ],
  },
  {
    path: '',
    loadChildren:  () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      title : 'Dashboard'
    },
    canActivate: [ authGuard ],
  },
  {
    path: 'invite/accept',
    component: InviteAcceptComponent,
    data: {
      title : 'Invite'
    },
    canActivate: [ noAuthGuard ],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title : 'Forgot Password'
    },
    canActivate: [ noAuthGuard ],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title : 'Reset Password'
    },
    canActivate: [ noAuthGuard ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title : 'Not Found'
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
