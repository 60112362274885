import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { Token } from '@angular/compiler';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token : string = '';
  form = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ]),
    password: new FormControl('', [ Validators.required, Validators.minLength(8) ]),
    password_confirmation: new FormControl('', [ Validators.required, Validators.minLength(8) ]),
  });
  loading : boolean = false;
  recaptchaV3ServiceSubscription : Subscription = new Subscription();

  constructor(
    private snackbarService: SnackbarService,
    private authService : AuthService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private activatedRoute : ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.snackbarService.openSnackbar('Please fill the login form.');
    } else {
      this.loading = true;

      this.recaptchaV3ServiceSubscription = this.recaptchaV3Service.execute('reset_password')
        .subscribe((recaptcha) => {
          this.authService.cookieInit().subscribe(() => {
            this.authService.resetPassword({
              token : this.token,
              email : this.form.get('email')?.value ?? '',
              password : this.form.get('password')?.value ?? '',
              password_confirmation : this.form.get('password_confirmation')?.value ?? '',
              recaptcha : recaptcha,
            }).subscribe({
                next: (response) => {
                  if (response.message) {
                    this.snackbarService.openSnackbar(response.message);
                  }
                  this.router.navigate(['']);
                  this.loading = false;
                },
                error: (error) => {
                  if (error.error?.message) {
                    this.snackbarService.openSnackbar( error.error.message );
                  }
                  this.loading = false;
                }
            });
          });
      });
    }
  }

  ngOnDestroy() {
    if (this.recaptchaV3ServiceSubscription) {
      this.recaptchaV3ServiceSubscription.unsubscribe();
    }
  }

}
