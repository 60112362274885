import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, tap } from 'rxjs';
import { Response } from '../interfaces/response';
import { Admin } from '../interfaces/admin';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiBase = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private adminService : AdminService,
  ) { }

  login(body : { email : string, password : string, recaptcha : string }): Observable<Response<Admin>> {
    return this.http.post<any>(`${this.apiBase}auth/admin/login`, body,{ withCredentials: true });
  }

  logout() {
    return this.http.get<any>(`${this.apiBase}auth/admin/logout`).pipe(
      tap({
        next: () => {
          this.adminService.admin.next(null);
        },
      })
    )
  }

  cookieInit() {
    return this.http.get<any>(`${this.apiBase}sanctum/csrf-cookie`);
  }

  getAdmin() : Observable<Admin | null> {
    if (this.adminService.admin.value) {
      return of(this.adminService.admin.value);
    } else {
      return this.http.get<any>(`${this.apiBase}api/auth/admin`,{ withCredentials: true }).pipe(
        tap({
          next: (response) => {
            this.adminService.admin.next(response.data);
          },
          error: () => {
            this.adminService.admin.next(null);
          },
        }),
        switchMap(() => this.adminService.admin.asObservable())
      );
    }
  }

  forgotPassword(body : { recaptcha : string, email : string }): Observable<Response<void>> {
    return this.http.post<any>(`${this.apiBase}api/publisher/forgot-password`, body);
  }

  resetPassword(body : { token : string, recaptcha : string, email : string, password : string, password_confirmation : string }): Observable<Response<void>> {
    return this.http.post<any>(`${this.apiBase}api/publisher/reset-password`, body);
  }
}
